module.exports = {
  REFERRAL_NETWORK: {
    key: 'REFERRAL_NETWORK',
    default_data: [
      {
        key: "ZALO",
        image: "https://stc-zaloprofile.zdn.vn/pc/v1/images/zalo_sharelogo.png",
        referral_id: "1433117008488711236"
      },
      {
        key: "MESSENGER",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Facebook_Messenger_logo_2020.svg/2048px-Facebook_Messenger_logo_2020.svg.png",
        referral_id: "104222198430912"
      }]
  },

  MODULE_CATEGORY: 'system',
}
