<template>
  <b-modal
    id="modal-scrollable"
    scrollable
    title="Sale Popup Content"
    cancel-title="Close"
    ok-title="Save"
    cancel-variant="outline-secondary"
    v-model="isOpen"
    @ok="save"
    size="xl"
  >
    <b-form-group label="Title" label-for="title">
      <b-form-input v-model="sale_popup.title" id="title" placeholder="Title" />
    </b-form-group>
    <b-form-group label="Subtitle" label-for="subtitle">
      <b-form-input v-model="sale_popup.subtitle" id="subtitle" placeholder="Subtitle" />
    </b-form-group>
    <b-form-group label="Type" label-for="type">
      <b-form-input v-model="sale_popup.type" id="type" placeholder="Type" />
    </b-form-group>
    <b-form-group label="Language" label-for="language">
      <!-- <b-form-select v-model="sale_popup.language" :options="languages" /> -->
      <language-select :language="sale_popup.language" @selectedLanguage="selectedLanguage" />
    </b-form-group>
    <b-form-group label="Content" label-for="content">
      <ui-component-list :component-list="sale_popup.content" />
    </b-form-group>

    <b-form-group label="AB Test Enable" label-for="ab_enable">
      <b-form-checkbox
        v-model="sale_popup.ab_enable"
        class="custom-control-primary"
        name="check-button"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="CheckIcon" />
        </span>
      </b-form-checkbox>
    </b-form-group>

    <b-form-group v-if="!sale_popup.ab_enable" label="Product" label-for="product">
      <b-card>
        <product-form-modal :products="sale_popup.products" />
      </b-card>
    </b-form-group>

    <b-form-group v-if="sale_popup.ab_enable" label="Products A" label-for="products_a">
      <b-card>
        <product-form-modal :products="sale_popup.products_a" :ab-test-enable="sale_popup.ab_enable" />
      </b-card>
    </b-form-group>

    <b-form-group v-if="sale_popup.ab_enable" label="Products B" label-for="products_b">
      <b-card>
        <product-form-modal :products="sale_popup.products_b" :ab-test-enable="sale_popup.ab_enable" />
      </b-card>
    </b-form-group>

    <b-form-group label="Button Text" label-for="button_text">
      <b-form-input v-model="sale_popup.button_text" />
    </b-form-group>

    <b-form-group label="Close Button Text" label-for="close_button_text">
      <b-form-input v-model="sale_popup.close_button_text" />
    </b-form-group>

    <b-form-group label="Button Sub Text" label-for="button_sub_text">
      <b-form-input v-model="sale_popup.button_sub_text" />
    </b-form-group>

    <b-form-group label="Icons" label-for="icons">
      <b-card>
        <icon-form-modal :icons="sale_popup.icons" />
      </b-card>
    </b-form-group>

    <b-form-group label="Alert Message" label-for="alert_message">
      <b-form-input v-model="sale_popup.alert_message" />
    </b-form-group>
  </b-modal>
</template>
<script>
import ProductFormModal from "./ProductFormModal.vue";
import IconFormModal from "./IconFormModal.vue";
export default {
  components: {
    ProductFormModal,
    IconFormModal,
  },
  props: {
    dataUpdate: { type: Object, default: null },
  },
  data() {
    return {
      isOpen: false,
      sale_popup: {
        title: null,
        type: null,
        language: "vi",
        content: [],
        button_text: null,
        products: [],
        products_a: [],
        products_b: [],
        icons: [],
        close_button_text: null,
        ab_enable: false,
      },
    };
  },
  watch: {
    dataUpdate: function (newValue) {
      if (newValue) {
        this.sale_popup = { ...this.sale_popup, ...newValue };
      } else {
        this.resetData();
      }
    },
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    save() {
      if (this.dataUpdate) {
        this.$emit("update", this.sale_popup);
      } else {
        this.$emit("create", this.sale_popup);
      }
      this.resetData();
    },
    resetData() {
      this.sale_popup = {
        title: null,
        type: null,
        language: "vi",
        content: [],
        button_text: null,
        products: [],
        products_a: [],
        products_b: [],
        icons: [],
        close_button_text: null,
        ab_enable: false,
      };
    },
    selectedLanguage(language) {
      this.sale_popup.language = language;
    },
  },
};
</script>
<style scoped>
.d-block {
  font-size: 1.1rem !important;
}
</style>
